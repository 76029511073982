import type {
    ILinkBaseProps,
    TComponentColor,
    TPaletteEmphasis
} from '@mcal/core-react';
import {getColorPalette} from '@mcal/core-react';
import {NavLink} from 'react-router-dom';
import {styled} from '../../utils/styled/styled.js';

interface IStyledLink {
    accent?: TComponentColor;
    emphasis?: TPaletteEmphasis;
    level?: 'base' | 'onBase';
    $lineHeight?: NonNullable<ILinkBaseProps<never>['lineHeight']>;
}
const StyledRoot = styled(NavLink)<IStyledLink>(
    ({
        theme,
        emphasis = 'high',
        accent = 'info',
        level = 'base',
        $lineHeight
    }) => ({
        color: getColorPalette(theme, accent, emphasis)[level],
        cursor: 'pointer',
        lineHeight: $lineHeight || theme.size.fixed.s0,
        textDecoration: 'none'
    })
);

export {StyledRoot};
