import type {
    ILinkBaseProps,
    TComponentColor,
    TPaletteEmphasis
} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './link.styles.js';

const ownTestIDs = createTestIDs('Link', ['root']);

interface ILinkProps extends ILinkBaseProps<typeof ownTestIDs> {
    accent?: TComponentColor;
    emphasis?: TPaletteEmphasis;
    level?: 'base' | 'onBase';
    className?: string;
    classes?: {
        root?: string;
    };
}

const Link: FC<ILinkProps> = ({
    children,
    className = '',
    classes = {},
    testIDs = {},
    to = '/',
    ...rest
}) => {
    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            to={to}
            {...rest}
        >
            {children}
        </StyledRoot>
    );
};

export type {ILinkProps};
export {Link, ownTestIDs as testIDs};
