import {styled} from '../../../../utils/styled/styled.js';
import {Link} from '../../../link/link.js';

const StyledRoot = styled(Link)(({theme}) => ({
    [theme.query.m]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 8px'
    },
    [theme.query.l]: {
        padding: '0 16px'
    }
}));

export {StyledRoot};
