import type {TTestIDs} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../../../dev/index.js';
import type {IIconProps} from '../../../icon/icon.js';
import {Icon} from '../../../icon/icon.js';
import {
    StyledActiveIndicator,
    StyledContainer,
    StyledLabel
} from '../common/common.styles.js';
import {StyledRoot} from './sidebar-item.styles.js';

const ownTestIDs = createTestIDs('SidebarItem', ['root']);

interface ISidebarItemProps {
    expanded?: boolean;
    active?: boolean;
    to: string;
    label: string;
    icon?: IIconProps['icon'];
    testIDs?: TTestIDs<typeof ownTestIDs>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const SidebarItem: FC<ISidebarItemProps> = ({
    to = '',
    label,
    active = false,
    expanded = false,
    icon,
    testIDs = ownTestIDs,
    className = 'string',
    classes = {}
}) => {
    return (
        <StyledRoot
            to={to}
            testIDs={testIDs}
            className={className}
            classes={classes}
        >
            <StyledContainer active={active}>
                <StyledActiveIndicator active={active} />

                {icon && (
                    <Icon
                        color={active ? 'primary' : 'neutral'}
                        emphasis={'high'}
                        icon={icon}
                        withBackground={false}
                    />
                )}

                <StyledLabel expanded={expanded} active={active} type={'small'}>
                    {label}
                </StyledLabel>
            </StyledContainer>
        </StyledRoot>
    );
};

export type {ISidebarItemProps};
export {SidebarItem, ownTestIDs as testIDs};
