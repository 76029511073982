import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface IMcallinnLogoIconSvgProps {
    height?: number | string;
    width?: number | string;
    monochrome?: boolean;
}

const McallinnLogoIconSvg: FC<IMcallinnLogoIconSvgProps> = ({
    monochrome,
    ...props
}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            if (monochrome) {
                return {
                    colorA: '#DDDEE1',
                    colorB: '#DDDEE1'
                };
            }
            return {
                colorA: '#37D683',
                colorB: '#D64637'
            };
        } else {
            if (monochrome) {
                return {
                    colorA: '#22252A',
                    colorB: '#22252A'
                };
            }
            return {
                colorA: '#0A8245',
                colorB: '#CC1906'
            };
        }
    }, [monochrome, type]);

    return (
        <svg
            width={'20'}
            height={'24'}
            viewBox={'0 0 20 24'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={'M19.2 22.08V24H0V22.08L1.92 20.88H17.28L19.2 22.08Z'}
                fill={colorB}
            />
            <path
                d={
                    'M19.2 0V1.92L17.28 3.12V5.7744L15.36 6.8832V3.12H10.56V9.6528L8.64 10.7616V3.12H3.84V13.5336L1.92 14.6424V3.12L0 1.92V0H19.2Z'
                }
                fill={colorA}
            />
            <path
                d={'M3.84004 15.1968V18.96H1.92004V16.3056L3.84004 15.1968Z'}
                fill={colorB}
            />
            <path
                d={'M7.20003 13.2576V18.96H5.28003V14.3664L7.20003 13.2576Z'}
                fill={colorB}
            />
            <path
                d={'M13.92 9.37683V18.96H12V10.4856L13.92 9.37683Z'}
                fill={colorB}
            />
            <path
                d={'M10.56 11.3184V18.96H8.64001V12.4272L10.56 11.3184Z'}
                fill={colorB}
            />
            <path
                d={'M17.28 7.43762V18.96H15.36V8.54642L17.28 7.43762Z'}
                fill={colorB}
            />
        </svg>
    );
};

export type {IMcallinnLogoIconSvgProps};
export {McallinnLogoIconSvg};
