import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IScheduleSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const ScheduleSvg: FC<IScheduleSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#7CA0E4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            width={'21'}
            height={'20'}
            viewBox={'0 0 21 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M13.8 14.7L15.2 13.3L11.5 9.6V5H9.5V10.4L13.8 14.7ZM10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C12.7167 18 14.6042 17.2208 16.1625 15.6625C17.7208 14.1042 18.5 12.2167 18.5 10C18.5 7.78333 17.7208 5.89583 16.1625 4.3375C14.6042 2.77917 12.7167 2 10.5 2C8.28333 2 6.39583 2.77917 4.8375 4.3375C3.27917 5.89583 2.5 7.78333 2.5 10C2.5 12.2167 3.27917 14.1042 4.8375 15.6625C6.39583 17.2208 8.28333 18 10.5 18Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {ScheduleSvg};
