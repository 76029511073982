import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IMaintenanceSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const MaintenanceSvg: FC<IMaintenanceSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#7CA0E4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            width={'21'}
            height={'20'}
            viewBox={'0 0 21 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M14 14.9L15.4 13.5C15.5 13.4 15.55 13.2833 15.55 13.15C15.55 13.0167 15.5 12.9 15.4 12.8L12 9.375C12.0667 9.19167 12.1167 9.00833 12.15 8.825C12.1833 8.64167 12.2 8.43333 12.2 8.2C12.2 7.25 11.8625 6.4375 11.1875 5.7625C10.5125 5.0875 9.7 4.75 8.75 4.75C8.46667 4.75 8.18333 4.7875 7.9 4.8625C7.61667 4.9375 7.34167 5.04167 7.075 5.175L9.425 7.525L8.025 8.925L5.675 6.575C5.54167 6.84167 5.4375 7.11667 5.3625 7.4C5.2875 7.68333 5.25 7.96667 5.25 8.25C5.25 9.2 5.5875 10.0125 6.2625 10.6875C6.9375 11.3625 7.75 11.7 8.7 11.7C8.91667 11.7 9.12083 11.6833 9.3125 11.65C9.50417 11.6167 9.69167 11.5667 9.875 11.5L13.3 14.9C13.4 15 13.5167 15.05 13.65 15.05C13.7833 15.05 13.9 15 14 14.9ZM10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C12.7333 18 14.625 17.225 16.175 15.675C17.725 14.125 18.5 12.2333 18.5 10C18.5 7.76667 17.725 5.875 16.175 4.325C14.625 2.775 12.7333 2 10.5 2C8.26667 2 6.375 2.775 4.825 4.325C3.275 5.875 2.5 7.76667 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {MaintenanceSvg};
