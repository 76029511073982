import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface ISafetySvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const SafetySvg: FC<ISafetySvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#7CA0E4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            width={'19'}
            height={'20'}
            viewBox={'0 0 19 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M8.798 0.195057C9.19262 0.0474088 9.62379 0.0281603 10.03 0.140057L10.202 0.195057L17.202 2.82006C17.5569 2.95315 17.8667 3.18436 18.0953 3.48676C18.3239 3.78916 18.4618 4.15028 18.493 4.52806L18.5 4.69306V10.0561C18.5 11.6765 18.0624 13.2668 17.2336 14.6592C16.4048 16.0517 15.2154 17.1945 13.791 17.9671L13.525 18.1061L10.171 19.7831C9.98632 19.8753 9.78461 19.9284 9.57847 19.939C9.37233 19.9497 9.16621 19.9177 8.973 19.8451L8.829 19.7831L5.475 18.1061C4.02561 17.3813 2.79878 16.2787 1.924 14.9147C1.04923 13.5506 0.558981 11.9756 0.505 10.3561L0.5 10.0561V4.69306C0.500006 4.31419 0.607627 3.94311 0.810334 3.62303C1.01304 3.30295 1.3025 3.04702 1.645 2.88506L1.798 2.82006L8.798 0.195057ZM9.5 2.06806L2.5 4.69306V10.0561C2.50003 11.3111 2.83745 12.543 3.47696 13.6228C4.11646 14.7027 5.03451 15.5908 6.135 16.1941L6.37 16.3171L9.5 17.8821L12.63 16.3171C13.7527 15.7558 14.7039 14.903 15.3839 13.8479C16.0638 12.7929 16.4476 11.5744 16.495 10.3201L16.5 10.0561V4.69306L9.5 2.06806ZM12.933 6.62906C13.113 6.44971 13.3544 6.34558 13.6084 6.33783C13.8623 6.33007 14.1097 6.41927 14.3003 6.58731C14.4908 6.75534 14.6103 6.98961 14.6344 7.24253C14.6585 7.49545 14.5854 7.74807 14.43 7.94906L14.347 8.04306L9.113 13.2781C8.92235 13.4687 8.66832 13.5827 8.39917 13.5983C8.13002 13.614 7.86449 13.5303 7.653 13.3631L7.557 13.2781L5.153 10.8741C4.97175 10.6945 4.86597 10.4525 4.85732 10.1975C4.84867 9.94253 4.9378 9.69389 5.10647 9.50248C5.27513 9.31106 5.51058 9.19135 5.76462 9.16785C6.01866 9.14435 6.27208 9.21883 6.473 9.37606L6.567 9.45906L8.335 11.2271L12.933 6.62906Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {SafetySvg};
