import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
    colorC: string;
}

interface IMcallinnLogoFullSvgProps {
    height?: number | string;
    width?: number | string;
    monochrome?: boolean;
}

const McallinnLogoFullSvg: FC<IMcallinnLogoFullSvgProps> = ({
    monochrome,
    ...props
}) => {
    const {type} = useTheme();

    const {colorA, colorB, colorC} = useMemo<IColors>(() => {
        if (type === 'dark') {
            if (monochrome) {
                return {
                    colorA: '#DDDEE1',
                    colorB: '#DDDEE1',
                    colorC: '#DDDEE1'
                };
            }
            return {
                colorA: '#37D683',
                colorB: '#D64637',
                colorC: '#DDDEE1'
            };
        } else {
            if (monochrome) {
                return {
                    colorA: '#22252A',
                    colorB: '#22252A',
                    colorC: '#22252A'
                };
            }
            return {
                colorA: '#0A8245',
                colorB: '#CC1906',
                colorC: '#22252A'
            };
        }
    }, [monochrome, type]);

    return (
        <svg
            width={'105'}
            height={'24'}
            viewBox={'0 0 105 24'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <g clipPath={'url(#clip0_655_1985)'}>
                <path
                    d={
                        'M23.5704 20.9616V3.2616H25.92L32.016 18.1464L38.112 3.2616H40.512V20.9616H38.1624V13.9608C38.1624 10.656 38.1864 9.456 38.3088 8.208L33.144 20.9616H30.8928L25.752 8.256C25.8744 9.1128 25.9224 10.9248 25.9224 13.3968V20.9616H23.5728H23.5704Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M48.0024 8.64722C51.0144 8.64722 53.1192 10.3608 53.5344 13.0296H51.2328C50.8416 11.5128 49.6176 10.704 48.0744 10.704C45.8952 10.704 44.4024 12.3696 44.4024 14.964C44.4024 17.5584 45.7968 19.2 47.976 19.2C49.6152 19.2 50.8392 18.3432 51.2568 16.9224H53.5584C53.0928 19.5168 50.8656 21.2544 47.976 21.2544C44.4504 21.2544 42.1248 18.7584 42.1248 14.988C42.1248 11.2176 44.4984 8.64722 48 8.64722H48.0024Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M54.5616 17.6329C54.5616 15.4297 56.2008 14.0593 59.016 13.8385L62.7864 13.5457V13.2025C62.7864 11.1961 61.5864 10.4857 59.9712 10.4857C58.0368 10.4857 56.9352 11.3425 56.9352 12.7873H54.9288C54.9288 10.2889 56.9856 8.64966 60.0696 8.64966C63.1536 8.64966 65.04 10.2169 65.04 13.4473V20.9641H63.0816L62.8368 19.0297C62.2248 20.4001 60.5856 21.2569 58.6512 21.2569C56.0808 21.2569 54.5616 19.8121 54.5616 17.6329ZM62.8128 15.7969V15.1609L59.7528 15.4057C57.72 15.6025 56.8896 16.3849 56.8896 17.5609C56.8896 18.8329 57.8208 19.4713 59.3136 19.4713C61.4928 19.4713 62.8152 18.0505 62.8152 15.7993L62.8128 15.7969Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M66.9481 20.9616V2.94482H69.2497V20.964H66.9481V20.9616Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M71.5992 20.9616V2.94482H73.9008V20.964H71.5992V20.9616Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M75.8112 4.63203C75.8112 3.77523 76.4976 3.11523 77.328 3.11523C78.1584 3.11523 78.8448 3.77523 78.8448 4.63203C78.8448 5.48883 78.1584 6.14883 77.328 6.14883C76.4976 6.14883 75.8112 5.46243 75.8112 4.63203ZM76.2024 8.99043H78.504V20.9616H76.2024V8.99043Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M80.7552 20.9616V8.99042H82.836L83.0808 10.8264C83.8392 9.45602 85.3584 8.64722 87.0456 8.64722C90.204 8.64722 91.6488 10.5816 91.6488 13.6416V20.9616H89.3472V14.1552C89.3472 11.7312 88.2216 10.752 86.4576 10.752C84.3024 10.752 83.0544 12.2952 83.0544 14.6208V20.9616H80.7528H80.7552Z'
                    }
                    fill={colorC}
                />
                <path
                    d={
                        'M93.7536 20.9616V8.99042H95.8344L96.0792 10.8264C96.8376 9.45602 98.3568 8.64722 100.044 8.64722C103.202 8.64722 104.647 10.5816 104.647 13.6416V20.9616H102.346V14.1552C102.346 11.7312 101.22 10.752 99.456 10.752C97.3008 10.752 96.0528 12.2952 96.0528 14.6208V20.9616H93.7512H93.7536Z'
                    }
                    fill={colorC}
                />
                <path
                    d={'M19.2 22.08V24H0V22.08L1.92 20.88H17.28L19.2 22.08Z'}
                    fill={colorB}
                />
                <path
                    d={
                        'M19.2 0V1.92L17.28 3.12V5.7744L15.36 6.8832V3.12H10.56V9.6528L8.64 10.7616V3.12H3.84V13.5336L1.92 14.6424V3.12L0 1.92V0H19.2Z'
                    }
                    fill={colorA}
                />
                <path
                    d={
                        'M3.84004 15.1968V18.96H1.92004V16.3056L3.84004 15.1968Z'
                    }
                    fill={colorB}
                />
                <path
                    d={
                        'M7.20003 13.2576V18.96H5.28003V14.3664L7.20003 13.2576Z'
                    }
                    fill={colorB}
                />
                <path
                    d={'M13.92 9.37683V18.96H12V10.4856L13.92 9.37683Z'}
                    fill={colorB}
                />
                <path
                    d={'M10.56 11.3184V18.96H8.64001V12.4272L10.56 11.3184Z'}
                    fill={colorB}
                />
                <path
                    d={'M17.28 7.43762V18.96H15.36V8.54642L17.28 7.43762Z'}
                    fill={colorB}
                />
            </g>
            <defs>
                <clipPath id={'clip0_655_1985'}>
                    <rect width={'104.647'} height={'24'} fill={'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export type {IMcallinnLogoFullSvgProps};
export {McallinnLogoFullSvg};
