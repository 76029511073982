import type {
    IUserAuthSettings,
    IUserGeneralSettings,
    IUserInviteEntry,
    IUserProfile,
    IUserSummary,
    IUserTripSettings,
    TAffinityProvider,
    TNestedPartial
} from '@mcal/core';
import {EAuthMFAType} from '@mcal/core';
import {createSlice} from '@reduxjs/toolkit';
import {objectURLs} from '../../modules/object-urls/object-urls.js';
import {
    associateSoftwareTOTP,
    toggleMFA
} from '../../services/app-node-auth/mfa/mfa.thunks.js';
import {logOut} from '../../services/app-node-auth/session/session.thunks.js';
import {
    getUser,
    updateEmail,
    updateGeneralSettings,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    updateProfilePhoto,
    verifyEmail,
    verifyPhoneNumber
} from '../../services/app-node-auth/user/user.thunks.js';
import {
    listUserInvites,
    respondInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {getSimpleFileId} from '../../utils/get-simple-file-id/get-simple-file-id.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import {initialState} from './user.state.js';

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state, {meta}) => {
            state.sources.getUser.status = 'LOADING';

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    'userSummary',
                    'userProfile',
                    'userGeneralSettings',
                    'userAuthSettings',
                    'userTripSettings'
                ]
            });
        });

        builder.addCase(getUser.fulfilled, (state, {meta, payload}) => {
            state.sources.getUser.status = 'IDLE';

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'userSummary',
                        replacer: (data): IUserSummary => {
                            return data.summary;
                        }
                    },
                    {
                        key: 'userProfile',
                        replacer: (data): IUserProfile => {
                            return data.profile;
                        }
                    },
                    {
                        key: 'userGeneralSettings',
                        replacer: (data): IUserGeneralSettings => {
                            return data.generalSettings;
                        }
                    },
                    {
                        key: 'userAuthSettings',
                        replacer: (data): IUserAuthSettings => {
                            return data.authSettings;
                        }
                    },
                    {
                        key: 'userTripSettings',
                        replacer: (data): IUserTripSettings => {
                            return data.tripSettings;
                        }
                    }
                ]
            });

            state.isEmailVerified = true;
            state.isPhoneNumberVerified = !!payload.data.summary.phoneNumber;
        });

        builder.addCase(getUser.rejected, (state, {meta, payload}) => {
            state.sources.getUser.status = 'FAILED';

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    'userSummary',
                    'userProfile',
                    'userGeneralSettings',
                    'userAuthSettings',
                    'userTripSettings'
                ]
            });
        });

        builder.addCase(updateGeneralSettings.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userGeneralSettings',
                        merger: (arg): TNestedPartial<IUserGeneralSettings> => {
                            return arg;
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateGeneralSettings.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'userGeneralSettings',
                            replacer: (data): IUserGeneralSettings => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateGeneralSettings.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.themeType) {
                    return;
                }
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userGeneralSettings']
                });
            }
        );

        builder.addCase(updateProfile.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userProfile',
                        mutator: (arg, current): void => {
                            current.firstName =
                                arg.firstName || current.firstName;
                            current.lastName = arg.lastName || current.lastName;
                        }
                    },
                    {
                        key: 'userSummary',
                        mutator: (arg, current): void => {
                            current.firstName =
                                arg.firstName || current.firstName;
                            current.lastName = arg.lastName || current.lastName;
                        }
                    }
                ]
            });
        });

        builder.addCase(updateProfile.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userProfile', 'userSummary']
            });
        });

        builder.addCase(updateProfile.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userProfile', 'userSummary']
            });
        });

        builder.addCase(updateProfilePhoto.pending, (state, {meta}) => {
            const photo = meta.arg.get('profilePhoto');

            if (!photo || !(photo instanceof File)) {
                return;
            }

            const url = objectURLs.set(getSimpleFileId(photo), photo, true);

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userProfile',
                        mutator: (_, current): void => {
                            current.photo = url;
                        }
                    },
                    {
                        key: 'userSummary',
                        mutator: (_, current): void => {
                            current.photo = url;
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateProfilePhoto.fulfilled,
            (state, {meta, payload}) => {
                const {current, updates} = state.remotes.userProfile;

                const update = updates[meta.requestId];

                if (update && update.rollback.photo) {
                    objectURLs.revoke(update.rollback.photo);
                }

                if (current.photo) {
                    objectURLs.alias(current.photo, payload.data.url);
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'userProfile',
                            mutator: (data, current): void => {
                                current.photo = data.url;
                            }
                        },
                        {
                            key: 'userSummary',
                            mutator: (data, current): void => {
                                current.photo = data.url;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateProfilePhoto.rejected,
            (state, {meta, payload}) => {
                const {current} = state.remotes.userProfile;

                if (current.photo) {
                    objectURLs.revoke(current.photo);
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userProfile', 'userSummary']
                });
            }
        );

        builder.addCase(updateEmail.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userSummary',
                        mutator: (arg, current): void => {
                            current.email = arg.email;
                        }
                    }
                ]
            });

            state.isEmailVerified = false;
        });

        builder.addCase(updateEmail.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userSummary']
            });
        });

        builder.addCase(updateEmail.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userSummary']
            });

            state.isEmailVerified = true;
        });

        builder.addCase(updatePhoneNumber.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userSummary',
                        mutator: (arg, current): void => {
                            current.phoneNumber = arg.phoneNumber;
                        }
                    }
                ]
            });

            state.isPhoneNumberVerified = false;
        });

        builder.addCase(
            updatePhoneNumber.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userSummary']
                });
            }
        );

        builder.addCase(
            updatePhoneNumber.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userSummary']
                });

                state.isPhoneNumberVerified = true;
            }
        );

        builder.addCase(updatePassword.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userAuthSettings',
                        mutator: (_, current): void => {
                            const match = current.flows.find((flow) => {
                                return flow.type === 'email-password';
                            });

                            if (match) {
                                match.updatedAt = Date.now();
                            }
                        }
                    }
                ]
            });
        });

        builder.addCase(updatePassword.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userAuthSettings']
            });
        });

        builder.addCase(updatePassword.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userAuthSettings']
            });
        });

        builder.addCase(verifyEmail.pending, (state) => {
            state.isEmailVerified = true;
        });

        builder.addCase(verifyEmail.rejected, (state) => {
            state.isEmailVerified = false;
        });

        builder.addCase(verifyPhoneNumber.pending, (state) => {
            state.isPhoneNumberVerified = true;
        });

        builder.addCase(verifyPhoneNumber.rejected, (state) => {
            state.isPhoneNumberVerified = false;
        });

        builder.addCase(associateSoftwareTOTP.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userAuthSettings',
                        mutator: (_, current): void => {
                            const match = current.mfa.find((mfa) => {
                                return mfa.type === EAuthMFAType.Software;
                            });

                            if (match) {
                                match.status = 'ENABLED';
                                match.updatedAt = Date.now();
                            }
                        }
                    }
                ]
            });
        });

        builder.addCase(
            associateSoftwareTOTP.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userAuthSettings']
                });
            }
        );

        builder.addCase(
            associateSoftwareTOTP.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['userAuthSettings']
                });
            }
        );

        builder.addCase(toggleMFA.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'userAuthSettings',
                        mutator: (arg, current): void => {
                            const match = current.mfa.find((mfa) => {
                                return mfa.type === arg.type;
                            });

                            if (match) {
                                match.status = arg.enable
                                    ? 'ENABLED'
                                    : 'DISABLED';

                                match.updatedAt = Date.now();
                            }
                        }
                    }
                ]
            });
        });

        builder.addCase(toggleMFA.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userAuthSettings']
            });
        });

        builder.addCase(toggleMFA.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['userAuthSettings']
            });
        });

        builder.addCase(listUserInvites.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });

        builder.addCase(listUserInvites.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'invites',
                        replacer: (
                            data
                        ): IUserInviteEntry<TAffinityProvider>[] => {
                            return data;
                        }
                    }
                ]
            });
        });

        builder.addCase(listUserInvites.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        builder.addCase(respondInvite.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'invites',
                        replacer: (
                            arg,
                            current
                        ): IUserInviteEntry<TAffinityProvider>[] => {
                            return current.filter(
                                (invite) => invite.secret !== arg.secret
                            );
                        }
                    }
                ]
            });
        });

        builder.addCase(respondInvite.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        builder.addCase(respondInvite.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        builder.addCase(logOut.fulfilled, (state) => {
            const currentSettings = state.remotes.userGeneralSettings.current;

            return {
                ...initialState,
                remotes: {
                    ...initialState.remotes,
                    userGeneralSettings: {
                        ...initialState.remotes.userGeneralSettings,
                        current: {
                            ...initialState.remotes.userGeneralSettings.current,
                            locale: currentSettings.locale,
                            themePack: currentSettings.themePack,
                            themeType: currentSettings.themeType
                        }
                    }
                }
            };
        });
    }
});

export {userSlice};
