import {styled} from '../../utils/styled/styled.js';

interface IStyledRootProps {
    expanded: boolean;
}
const StyledRoot = styled('div')<IStyledRootProps>(({theme, expanded}) => ({
    display: 'none',
    [theme.query.l]: {
        background: theme.palette.surface.base.high,
        height: '100vh',
        width: expanded ? '220px' : 'min-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        zIndex: theme.depth.global.z7,
        position: 'sticky',
        top: 0
    }
}));

export {StyledRoot};
