import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IRealTimeMonitoringSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const RealTimeMonitoringSvg: FC<IRealTimeMonitoringSvgProps> = ({
    fill,
    ...props
}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#7CA0E4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            width={'25'}
            height={'16'}
            viewBox={'0 0 25 16'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <rect
                x={'3.5'}
                y={'1'}
                width={'18'}
                height={'12'}
                rx={'1'}
                stroke={fill || colorA}
                strokeWidth={'2'}
            />
            <rect
                x={'0.5'}
                y={'14'}
                width={'24'}
                height={'2'}
                rx={'1'}
                fill={fill || colorA}
            />
            <path
                d={'M8.5 7L10.5 9L14.5 5L16.5 7'}
                stroke={fill || colorA}
                strokeWidth={'2'}
                strokeLinecap={'round'}
            />
        </svg>
    );
};

export {RealTimeMonitoringSvg};
